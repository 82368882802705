import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import dayjs from 'dayjs';

const launchDate = dayjs('2025-03-24T00:00:00');

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = dayjs();
      const diff = launchDate.diff(now, 'second');

      setTimeLeft({
        days: Math.floor(diff / (60 * 60 * 24)),
        hours: Math.floor((diff % (60 * 60 * 24)) / (60 * 60)),
        minutes: Math.floor((diff % (60 * 60)) / 60),
        seconds: diff % 60,
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box textAlign="center">
      <Typography variant="h4">Launching in:</Typography>
      <Typography variant="h2">
        {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
      </Typography>
    </Box>
  );
};

export default CountdownTimer;
