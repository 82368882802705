import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#1976d2' }, // Match StudioFlow branding
    secondary: { main: '#ff4081' },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: { fontSize: '2.5rem', fontWeight: 600 },
    h2: { fontSize: '2rem', fontWeight: 500 },
    body1: { fontSize: '1.2rem' },
  },
});

export default theme;
