import React, { useState } from 'react';
import { Box, Typography, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { motion } from 'framer-motion'; // npm install framer-motion
import CountdownTimer from '../components/CountdownTimer';
import { Helmet } from 'react-helmet';
import ReactDOMServer from 'react-dom/server';
import SchemaIcon from '@mui/icons-material/Schema';
import CloudIcon from '@mui/icons-material/Cloud';


const LandingPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState(''); // 'login' or 'signup'
  const [email, setEmail] = useState('');

  const handleDialogOpen = (type) => {
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEmail('');
  };

  const handleSignupSubmit = () => {
    if (email) {
      // Simulate signup action (e.g., send to Firebase or email service)
      console.log(`Pre-registered: ${email}`);
      handleDialogClose();
    }
  };

  const schemaIconSvg = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<SchemaIcon />));

  return (
    <>
      <Helmet>
        <title>StudioFlow | Unleash Your Creative Workflow</title>
        <link rel="icon" href={`data:image/svg+xml,${schemaIconSvg}`} />
      </Helmet>

      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Animated Background Element */}
        <motion.div
          initial={{ scale: 1.2, opacity: 0.3 }}
          animate={{ scale: 1, opacity: 0.6 }}
          transition={{ duration: 5, repeat: Infinity, repeatType: 'reverse' }}
          style={{
            position: 'absolute',
            top: '-20%',
            left: '-20%',
            width: '140%',
            height: '140%',
            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
          }}
        />

        <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
          <Box textAlign="center" sx={{ py: 8 }}>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, fontWeight: 700, color: '#fff', mb: 2 }}
            >
              Introducing Studiovault
            </Typography>

<Typography
  variant="body1"
  sx={{ color: 'rgba(255,255,255,0.9)', maxWidth: '600px', mx: 'auto', mb: 4, lineHeight: 1.6 }}
>
  StudioFlow’s premier digital asset management solution is designed to streamline your creative workflow. Launching on March 24, 2025, Studiovault sets the stage for a suite of innovative modules rolling out in the weeks that follow.
</Typography>



            </motion.div>

            {/* Responsive Image Added */}
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/studfoflow_on_devices.jpg`}
              alt="StudioFlow on Devices"
              sx={{
                width: '100%',
                maxWidth: 600,
                borderRadius: 2,
                boxShadow: 3,
                mb: 4,
                mx: 'auto',
              }}
            />

            <Box sx={{ mb: 6 }}>
            <Typography variant="body2" sx={{ color: '#fff', fontWeight: 'bold', mb: 2 }}>
              Studiovault Drops March 24, 2025
            </Typography>

              <CountdownTimer />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudIcon />}
                onClick={() => handleDialogOpen('login')}
                sx={{
                  px: 4,
                  py: 1.5,
                  fontSize: '1.1rem',
                  transition: 'transform 0.2s',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              >
                Get a Sneak Peek
              </Button>
              <Button
                variant="outlined"
                sx={{
                  px: 4,
                  py: 1.5,
                  fontSize: '1.1rem',
                  color: '#fff',
                  borderColor: '#fff',
                  transition: 'transform 0.2s',
                  '&:hover': { transform: 'scale(1.05)', borderColor: '#fff', background: 'rgba(255,255,255,0.1)' },
                }}
                onClick={() => handleDialogOpen('signup')}
              >
                Join the Waitlist
              </Button>
            </Box>
          </Box>
        </Container>

        {/* Dialog for Login/Signup */}
        <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ textAlign: 'center', color: '#1e3c72' }}>
          {dialogType === 'login' ? 'Early Access Coming Soon' : 'Join the StudioFlow Waitlist'}
        </DialogTitle>
        <DialogContent>
          {dialogType === 'login' ? (
            <Typography variant="body1" align="center">
              We’re finalizing Studiovault for early adopters. Stay tuned for the complete StudioFlow suite!
            </Typography>
          ) : (
            <Typography variant="body1" align="center">
              To join the waitlist, please send an email to{' '}
              <a href="mailto:info@studioflow.app" style={{ color: '#1e3c72', textDecoration: 'underline' }}>
                info@studioflow.app
              </a>.
            </Typography>
          )}
        </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
            <Button onClick={handleDialogClose} color="inherit" variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default LandingPage;
