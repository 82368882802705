import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import ReactDOMServer from 'react-dom/server';
import SchemaIcon from '@mui/icons-material/Schema';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Convert the MUI icon into an SVG string and encode it for use in a data URL.
const muiIconSvg = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<SchemaIcon />));

const Root = () => (
  <>
    <Helmet>
      <link rel="icon" href={`data:image/svg+xml,${muiIconSvg}`} />
      <title>StudioFlow | Unleash Your Creative Workflow</title>
    </Helmet>
    <App />
  </>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

reportWebVitals();
